<template>
  <a-card :bordered="false">
    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="GLOBAL.queryRowGutter">
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="流水号">
              <a-input v-model.trim="queryParam.record_no" placeholder="根据流水号搜索" />
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="部门">
              <a-select
                showSearch
                placeholder="请选择部门"
                style="width: 100%"
                v-model="queryParam.department"
                @change="$refs.table.refresh(true)"
              >
                <a-select-option v-for="op in GLOBAL.finDepartment" :key="op.value">{{ op.name }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="客户名称">
              <a-select
                showSearch
                label-in-value
                allowClear
                placeholder="请选择委托客户"
                style="width: 100%"
                :value="customerInfo"
                :filter-option="false"
                :not-found-content="fetching ? undefined : null"
                @search="getDepartmentCustomer"
                @change="changeCustomerInfo"
              >
                <template v-if="fetching" #notFoundContent>
                  <a-spin size="small" />
                </template>
                <a-select-option v-for="op in customerOps" :key="op.value">{{ op.name }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="状态">
              <a-select
                allowClear
                placeholder="请选择状态"
                style="width: 100%"
                v-model="queryParam.fee_status"
                @change="$refs.table.refresh(true)"
              >
                <a-select-option key="3">商务待审核</a-select-option>
                <a-select-option key="5">待开票</a-select-option>
                <a-select-option key="6">已开票</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="凭证号">
              <a-input v-model.trim="queryParam.cert_num" placeholder="根据凭证号搜索" />
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="凭证状态">
              <a-select
                allowClear
                style="width: 100%"
                v-model="queryParam['cert_status']"
              >
                <a-select-option v-for="op in certStatusOps" :key="op.value">{{ op.name }} </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <!-- <a-col :span="3">
            <a-form-item label="未下载">
              <a-checkbox-group v-model="queryParam['is_download_cert_array']">
                <a-checkbox value="N"></a-checkbox>
              </a-checkbox-group>
            </a-form-item>
          </a-col> -->
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="业务编号">
              <a-input v-model.trim="queryParam.business_no" placeholder="根据业务编号搜索" />
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="认领状态">
              <a-select
                allowClear
                placeholder="请选择资金认领状态"
                style="width: 100%"
                v-model="queryParam.claim_flag"
                @change="$refs.table.refresh(true)"
              >
                <a-select-option key="0">未认领</a-select-option>
                <a-select-option key="1">认领完成</a-select-option>
                <a-select-option key="2">部分认领</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan" v-if="queryParam.department === 1">
            <a-form-item label="业务类型">
              <a-select
                allowClear
                placeholder="请选择业务类型"
                style="width: 100%"
                v-model="queryParam.order_type"
                @change="$refs.table.refresh(true)"
              >
                <a-select-option v-for="op in wmsOrderTypeOps" :key="op.value">{{ op.name }} </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan" v-if="queryParam.department === 2">
            <a-form-model-item label="订单类型">
              <a-select
                allowClear
                placeholder="请选择订单类型"
                style="width: 100%"
                v-model="queryParam['order_type']"
                @change="$refs.table.refresh(true)"
              >
                <a-select-option v-for="op in orderTypeOps" :key="op.value">{{ op.name }}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="发票号">
              <a-input v-model.trim="queryParam.invoice_no" placeholder="根据发票号搜索" />
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="开票时间">
              <a-range-picker @change="onInvoiceDateChange"/>
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan" v-if="queryParam.department === 8">
            <a-form-item label="入库编号">
              <a-input v-model.trim="queryParam.jxr_business_no" placeholder="根据入库编号搜索" />
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </div>

    <div class="table-operator">
      <a-button type="query" icon="search" @click="$refs.table.refresh(true)">查询</a-button>
      <!-- <a-button type="primary" :disabled="!selectedRowKeys.length" @click="handleCheck('all')">核销</a-button>
      <a-button type="primary" :disabled="!selectedRowKeys.length" @click="handleReverseCheck('all')">反核销</a-button> -->
      <a-button type="danger" :disabled="!selectedRowKeys.length" @click="batchGenerateCert">批量生成凭证</a-button>
      <a-button type="primary" :disabled="!selectedRowKeys.length" @click="batchHandleConfirm">批量确认凭证</a-button>
      <a-button type="primary" :disabled="!selectedRowKeys.length" @click="downloadCert('all')">下载凭证</a-button>
    </div>

    <s-table
      ref="table"
      size="small"
      rowKey="id"
      :columns="columns"
      :data="loadData"
      :filterColumn="true"
      :rowSelection="{ selectedRowKeys: this.selectedRowKeys, onChange: this.onSelectChange }"
      :scroll="{ x: 'max-content' }"
    >
      <span slot="serial" slot-scope="text, record, index">
        {{ index + 1 }}
      </span>
      <div slot="businessNo" slot-scope="text, record">
        <a-popover placement="bottom">
          {{ text[0] }} {{ text.length !== 1 ? `(${text.length})单` : null }}
          <a-tag color="red" v-if="record.have_last_add_fees===1">后补费用</a-tag>
          <template slot="content">
            <span style="display:block" v-for="item in text" :key="item">{{ item }}</span>
          </template>
        </a-popover>
      </div>
      <div slot="invoiceDetail" slot-scope="text">
        <a-popover placement="bottom">
          <template v-if="text && text.length > 0">
            {{ text[0].invoice_no }}/税额:{{ text[0].tax }}/创建日:{{ moment(text[0].created_at).format('YYYY/MM/DD') }}
            {{ text.length !== 1 ? `(${text.length})单` : null }}
          </template>
          <template slot="content">
            <span style="display:block" v-for="item in text" :key="item.invoice_no">
              {{ item.invoice_no }}/税额:{{ item.tax }}/创建日:{{ moment(item.created_at).format('YYYY/MM/DD') }}
            </span>
          </template>
        </a-popover>
      </div>
      <div slot="certInfo" slot-scope="text">
        <span style="display:block" v-for="item in text" :key="item.id">{{ item.cert_num + '(' + transCertStatus(item.cert_status) + ')' }}</span>
      </div>
      <span slot="action" slot-scope="text, record">
        <template>
          <a @click="previewCert(record)" style="margin-right: 15px">预览凭证</a>
        </template>
      </span>
    </s-table>

    <a-modal
      :visible="certDetailVisible"
      title="凭证信息"
      :width="1500"
      :maskClosable="false"
      @cancel="certDetailVisible = false"
    >
      <empty v-if="certData.length === 0" />
      <a-card title="转账凭证" v-if="certData.length > 0">
        <a-tabs v-model="currentCertId" type="card">
          <a-tab-pane v-for="item in certData" :key="item.id" :tab="item.cert_num + ' (' + transCertStatus(item.cert_status) + ')'">
            <a-table
              rowKey="id"
              :columns="certColumns"
              :data-source="item.cert_info_list"
              :pagination="false"
              :customRow="
                (record, index) => {
                  return {
                    style: {
                      background: item.cert_status === 1 ? '#ffe8e9' : item.cert_status === 2 ? '#f8fadf' : '#e7ffec'
                    }
                  }
                }
              "
            >
            </a-table>
          </a-tab-pane>
        </a-tabs>
      </a-card>
      <template slot="footer">
        <a-button type="danger" @click="delCertInfo">
          删除凭证
        </a-button>
        <a-button type="primary" @click="generateCert">
          重新生成凭证
        </a-button>
        <a-button type="primary" @click="handleConfirm">
          确认凭证
        </a-button>
        <a-button type="primary" @click="handleUnConfirm">
          反确认凭证
        </a-button>
        <a-button type="primary" @click="downloadCert">
          下载凭证
        </a-button>
        <!-- <a-button type="primary" @click="printCert">
          打印凭证
        </a-button> -->
        <a-button @click="certDetailVisible = false">
          关闭
        </a-button>
      </template>
    </a-modal>
    <a-modal
      :visible="printCertModelVisible"
      :confirmLoading="printCertModelLoading"
      :width="1520"
      :maskClosable="false"
      title="打印凭证"
      @cancel="printCertModelVisible = false">
      <div id="printCertDataBill">
        <fms-cert-data-bill
          :certData="certPrintData"
          :name="certPrintName"
        />
      </div>
      <template slot="footer">
        <a-button key="submit"
                  type="primary"
                  id="printCertDataBillBtn"
                  v-print="printCertDataBill">确认打印</a-button>
        <a-button type="primary" @click="printCertModelVisible = false">关闭</a-button>
      </template>
    </a-modal>
  </a-card>
</template>
<script>
import { STable } from '@/components'
import { filterOption } from '@/utils/util'
import moment from 'moment'
import {
  getBizCheckPage,
  fmsBizAuditCheck,
  fmsBizAuditUnCheck,
  downloadCertById,
  previewCertById,
  delCert,
  certConfirm,
  certUnConfirm,
  generateCommonCert,
  batchGenerateCommonCert,
  batchCertConfirm
} from '@/api/fms'
import { downloadExcel, getCustomer } from '@/api/common'
import debounce from 'lodash/debounce'
import { Empty } from 'ant-design-vue'
import FmsCertDataBill from '@/views/fms/bill/FmsCertDataBill'
export default {
  components: {
    STable,
    Empty,
    FmsCertDataBill
  },
  data() {
    this.lastFetchId = 0
    this.getDepartmentCustomer = debounce(this.getDepartmentCustomer, 800)
    return {
      queryParam: {
        department: 1,
        cert_status: undefined,
        order_type: 1
      },
      selectedRowKeys: [],
      printCertModelVisible: false,
      printCertModelLoading: false,
      printCertDataBill: {
        id: `printCertDataBill`,
        popTitle: ``,
        extraCss: ``,
        extraHead: `<meta http-equiv="Content-Language" content="zh-cn" />`
      },
      columns: [
        {
          title: '#',
          scopedSlots: { customRender: 'serial' },
          width: 50,
          align: 'center'
        },
        {
          title: '流水号',
          dataIndex: 'record_no'
        },
        {
          title: '业务编号',
          dataIndex: 'business_no',
          scopedSlots: { customRender: 'businessNo' }
        },
        {
          title: '业务类型',
          dataIndex: 'order_type_name'
        },
        {
          title: '部门',
          dataIndex: 'department',
          customRender: text => {
            return this.GLOBAL.departmentMaps[text].name
          }
        },
        {
          title: '审核状态',
          dataIndex: 'status',
          customRender: text => {
            return text >= 0 ? this.GLOBAL.feeStatusMaps[text].name : ''
          }
        },
        {
          title: '凭证号',
          dataIndex: 'cert_relation',
          scopedSlots: { customRender: 'certInfo' }
        },
        {
          title: '客户名称',
          dataIndex: 'customer_name'
        },
        {
          title: '开票金额',
          dataIndex: 'sum_amount',
          customRender: text => {
            return (text.toFixed(2) + '').replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g, '$1,')
          }
        },
        {
          title: '已认领金额',
          dataIndex: 'amount',
          customRender: text => {
            return (text.toFixed(2) + '').replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g, '$1,')
          }
        },
        {
          title: '发票号',
          dataIndex: 'invoice_detail',
          scopedSlots: { customRender: 'invoiceDetail' }
        },
        {
          title: '操作',
          dataIndex: 'action',
          scopedSlots: { customRender: 'action' },
          fixed: 'right'
        }
      ],
      certColumns: [
        {
          title: '摘要',
          dataIndex: 'summary',
          width: 300
        },
        {
          title: '业务编号/发票号',
          dataIndex: 'number',
          width: 120
        },
        {
          title: '一级科目',
          dataIndex: 'first_subject',
          width: 200
        },
        {
          title: '明细科目',
          dataIndex: 'detail_subject',
          width: 200
        },
        {
          title: '外币金额',
          dataIndex: 'amount',
          width: 100
        },
        {
          title: '汇率',
          dataIndex: 'exchange_rate',
          width: 100
        },
        {
          title: '金额',
          children: [
            {
              title: '借方',
              dataIndex: 'borrow',
              customRender: text => {
                return text && (text.toFixed(2) + '').replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g, '$1,')
              },
              align: 'right',
              width: 100
            },
            {
              title: '贷方',
              dataIndex: 'loan',
              customRender: text => {
                return text && (text.toFixed(2) + '').replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g, '$1,')
              },
              align: 'right',
              width: 100
            }
          ]
        }
      ],
      currentCertRecord: {},
      currentCertId: undefined,
      currentCert: {},
      certDetailVisible: false,
      certData: [],
      fetching: false,
      customerOps: [],
      customerInfo: undefined,
      certPrintData: [],
      certPrintName: '',
      loadData: parameter => {
        // if (this.queryParam.is_download_cert_array && this.queryParam.is_download_cert_array.length > 0) {
        //   this.queryParam.is_download_cert = this.queryParam.is_download_cert_array[0]
        // } else {
        //   this.queryParam.is_download_cert = null
        // }
        return getBizCheckPage('check_list', Object.assign(parameter, this.queryParam)).then(res => {
          return res
        })
      },
      certStatusOps: [
        {
          value: 1,
          name: '待确认'
        },
        {
          value: 2,
          name: '已确认'
        },
        {
          value: 3,
          name: '已下载'
        }
      ],
      wmsOrderTypeOps: [
        {
          value: 1, name: '查验'
        },
        {
          value: 2, name: '暂落箱'
        },
        {
          value: 3, name: '仓储租赁'
        }
      ],
      orderTypeOps: [
        { name: '国贸', value: 1 },
        { name: '奥货', value: 2 },
        { name: '订舱', value: 3 },
        { name: '拖运业务', value: 5 }
      ]
    }
  },
  methods: {
    moment,
    filterOption,
    onInvoiceDateChange(date) {
      if (date[0]) {
        this.queryParam['invoice_start_date'] = date[0].startOf('day').format('YYYY-MM-DD HH:mm:ss')
        this.queryParam['invoice_end_date'] = date[1].endOf('day').format('YYYY-MM-DD HH:mm:ss')
      } else {
        this.queryParam['invoice_start_date'] = null
        this.queryParam['invoice_end_date'] = null
      }
    },
    getDepartmentCustomer(value) {
      this.lastFetchId += 1
      const fetchId = this.lastFetchId
      this.customerOps = []
      this.fetching = true
      getCustomer({
        department: null,
        customerName: value
      }).then(res => {
        if (fetchId !== this.lastFetchId) {
          return
        }
        this.customerOps = res
        this.fetching = false
      })
    },
    changeCustomerInfo(value) {
      if (typeof value !== 'undefined') {
        this.customerInfo = value
        this.queryParam.customer_name = value.key
        this.customerOps = []
        this.fetching = false
      } else {
        this.customerInfo = value
        this.queryParam.customer_name = null
        this.customerOps = []
        this.fetching = false
      }
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
    },
    handleCheck(flag, record) {
      this.$confirm({
        title: '确认提示',
        content: '确定要核销？',
        onOk: () => {
          let _ids = []
          if (flag === 'all') {
            _ids = this.selectedRowKeys
          } else {
            _ids = [record.id]
          }
          const data = {
            ids: _ids
          }
          fmsBizAuditCheck(data).then(_ => {
            this.$notification['success']({
              message: '提示',
              description: '操作成功.'
            })
            this.$refs.table.refresh(false)
          })
        }
      })
    },
    handleReverseCheck(flag, record) {
      this.$confirm({
        title: '确认提示',
        content: '确定要取消核销？',
        onOk: () => {
          let _ids = []
          if (flag === 'all') {
            _ids = this.selectedRowKeys
          } else {
            _ids = [record.id]
          }
          fmsBizAuditUnCheck(_ids).then(_ => {
            this.$notification['success']({
              message: '提示',
              description: '操作成功.'
            })
            this.$refs.table.refresh(false)
          })
        }
      })
    },
    // 凭证table生成
    previewCert(record) {
      this.currentCertRecord = record
      this.previewCommonCert(record)
      this.certDetailVisible = true
    },
    // 转账凭证
    previewCommonCert(record) {
      previewCertById({
        id: record.id,
        type: 1,
        batchNum: record.batch_num
      }).then(res => {
        this.certData = res || []
        if (res && res.length > 0) {
          let cert = null
          for (const item of res) {
            if (item.id === this.currentCertId) {
              cert = item
            }
          }
          if (cert) {
            this.currentCertId = cert.id
            this.currentCert = cert
          } else {
            this.currentCertId = res[0].id
            this.currentCert = res[0]
          }
        }
      })
    },
    generateCert() {
      generateCommonCert({
        id: this.currentCertRecord.id
      }).then(res => {
        if (res) {
          this.$message.success('已生成凭证')
          this.$refs.table.refresh(false)
          this.previewCommonCert(this.currentCertRecord)
        }
      })
    },
    batchGenerateCert() {
      this.$confirm({
        title: '确认生成凭证?',
        content: '是否要为所选的未生成凭证的单据生成凭证?',
        okText: '确认',
        cancelText: '取消',
        onOk: () => {
          batchGenerateCommonCert(this.selectedRowKeys).then(res => {
            this.$notification['success']({
                message: '提示',
                description: res
              })
              this.$refs.table.refresh(false)
          })
        }
      })
    },
    batchHandleConfirm() {
      // const ids = []
      // debugger
      // this.selectedRows.forEach(row => {
      //   if (row.cert_relation) {
      //     row.cert_relation.forEach(item => {
      //       ids.push(item.id)
      //     })
      //   }
      // });
      if (this.selectedRowKeys.length > 0) {
        batchCertConfirm(this.selectedRowKeys).then(res => {
          if (res) {
            this.$message.success('凭证确认成功')
            this.$refs.table.refresh(false)
          }
        })
      }
    },
    downloadCert(flag) {
      let list = []
      if (flag === 'all') {
        list = this.selectedRowKeys
      } else {
        list.push(this.currentCertRecord.id)
      }
      downloadCertById({
        list,
        type: 1
      }).then(res => {
        if (res !== null) {
          const fileInfo = {
            fileName: res
          }
          downloadExcel(fileInfo).then(downRes => {
            const data = downRes.data
            let fileName = ''
            const contentDisposition = downRes.headers['content-disposition']
            if (contentDisposition) {
              fileName = decodeURI(escape(contentDisposition.split('=')[1]))
            }
            const url = window.URL.createObjectURL(
              new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
            )
            const link = document.createElement('a')
            link.style.display = 'none'
            link.href = url
            link.setAttribute('download', fileName)
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
            this.$refs.table.refresh(false)
            if (flag !== 'all') {
              this.previewCommonCert(this.currentCertRecord)
            }
          })
        }
      })
    },
    printCert() {
      this.certPrintData = this.currentCert.cert_info_list
      this.certPrintName = '转账凭证'
      this.printCertModelVisible = true
    },
    moneyFormatter(money) {
      return (money.toFixed(2) + '').replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g, '$1,')
    },
    delCertInfo() {
      delCert({
        id: this.currentCertId
      }).then(res => {
        if (res) {
          this.$message.success('删除凭证成功')
          this.$refs.table.refresh(false)
          this.certData = []
          // this.previewChargeCert(this.currentCertRecord)
        }
      })
    },
    handleConfirm() {
      certConfirm({
        id: this.currentCertId
      }).then(res => {
        if (res) {
          this.$message.success('凭证确认成功')
          this.$refs.table.refresh(false)
          this.previewCommonCert(this.currentCertRecord)
        }
      })
    },
    handleUnConfirm() {
      certUnConfirm({
        id: this.currentCertId
      }).then(res => {
        if (res) {
          this.$message.success('凭证反确认成功')
          this.$refs.table.refresh(false)
          this.previewCommonCert(this.currentCertRecord)
        }
      })
    },
    transCertStatus(status) {
      switch (status) {
        case 1:
          return '待确认'
        case 2:
          return '已确认'
        case 3:
          return '已下载'
        default:
          return ''
      }
    }
  }
}
</script>

<style>
.margin-left15 {
  margin-left: 15px;
}
.cert_wait_confirm_color {
  color: #ffe8e9;
}
.cert_confirm_color {
  color: #f8fadf;
}
.cert_dowload_color {
  color: #e7ffec;
}
</style>
