<template>
  <div id="print"><p style="text-align: center; font-weight: 600">{{ certName }}</p>
    <p></p>
    <div>
      <table>
        <tbody>
          <tr>
            <th rowspan="2">摘要</th>
            <th rowspan="2">业务编号/发票号</th>
            <th rowspan="2">一级科目</th>
            <th rowspan="2">明细科目</th>
            <th rowspan="2">外币金额</th>
            <th rowspan="2">汇率</th>
            <th colspan="2">金额</th>
          </tr>
          <tr>
            <td>借方</td>
            <td>贷方</td>
          </tr>
          <tr v-for="item in certList" :key="item.id">
            <td>{{ item.summary }}</td>
            <td>{{ item.number }}</td>
            <td>{{ item.first_subject }}</td>
            <td>{{ item.detail_subject }}</td>
            <td class="w100">{{ item.amount }}</td>
            <td class="w100">{{ item.exchange_rate }}</td>
            <td class="w100">{{ moneyFormatter(item.borrow) }}</td>
            <td class="w100">{{ moneyFormatter(item.loan) }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>

import moment from 'moment'

export default {
  name: 'FmsCertDataBill',
  components: {},
  props: {
    name: {
      type: String,
      default: null
    },
    certData: {
      type: Array,
      default: null
    }
  },
  created() {
    this.initData()
  },
  watch: {
    name(val, oldVal) {
      this.certName = val
    },
    certData: {
      handler(val, oldVal) {
        this.initData()
      },
      deep: true
    }
  },
  data() {
    return {
      certName: '',
      certList: [],
      userInfo: this.$store.getters.userInfo,
      date: moment().year() + '-' + (moment().month() + 1) + '-' + moment().date()
    }
  },
  methods: {
    initData() {
      this.certName = this.name
      this.certList = this.certData
    },
    moneyFormatter(money) {
      if (typeof money !== 'number') {
        return ''
      }
      return (money.toFixed(2) + '').replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g, '$1,')
    }
  }
}
</script>

<style scoped>
@page{
  size:  auto;   /* auto is the initial value */
  margin: 3mm;  /* this affects the margin in the printer settings */
}

html{
  background-color: #FFFFFF;
  margin: 0px;  /* this affects the margin on the html before sending to printer */
}

body{
  border: solid 1px blue ;
  width: 80%; margin: 100px auto;
}
#print { width: 90%; margin: 0px auto; min-width:1000px }
#print p, #print table { width: 100% }
table { border-collapse: collapse; text-align: center; }
table td, th { border: 1px solid #000; }
.w50 { width:50px }
.w100 { width:100px }
.w200 { width:200px }
.table2 { font-size: 12px; }
.table2 tr td:nth-child(1) { border-left: 0; }
.table2 tr td:nth-last-child(1) { border-right: 0; }
.table2 tr:nth-child(1) td{ border-top: 0; }
.table2 tr:nth-last-child(1) td{ border-bottom: 0; }
#footer span { display: inline-block; width: 20% }
#footer span:last-child { width: 30% }
</style>
